<template>
    <div class="coordination-container">
        <ListData viewerKey='myJoin'></ListData>
    </div>
</template>

<script>
import ListData from './components/List.vue'
export default {
	components:{
		ListData
	},
	data() {
		return {
		};
	},
	computed: { },
	methods: {
	},
	created() {},
	mounted() {},
};
</script>

<style lang='less' scoped>
@import "../../styles/index";
.coordination-container {
	.border-style{
		border: 1px solid #DCDFE6;
		box-sizing: border-box;
		padding: 10px 20px;
		margin-bottom: 20px;
	}
	.coordination-title{
		margin: 0 0 10px 0;
		font-weight: bold;
		color: #2c3e50;
		font-size: 18px;
	}
	/deep/.el-col-5{
		float: right;
		width: 23%;
	}
	.coordination-trends{
		margin-top: 20px;
	}
	.create-container /deep/.el-button{
		width: 100%;
	}
	/deep/.el-tabs__item{
		font-size: 18px;
		color: #999;
		font-weight: normal;
	}
	/deep/.el-tabs__item.is-active {
		color: #2c3e50;
		font-weight: bold;
	}
	/deep/.el-tabs__active-bar{
		background-color: red;
	}
	/deep/.el-timeline{
		padding-left: 0;
		.el-card__body{
			padding: 10px;
			h4{
				margin: 0 0 10px 0;
			}
			p{
				font-size: 12px;
				margin: 0;
				color: #666;
			}
		}
	}
}

</style>